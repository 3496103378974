@font-face {
  font-style: normal;
  font-weight: normal;
  src: url("https://litmus.com/fonts/Emails/proximanova-regular-webfont.woff");
  font-family: "proxima_nova_rgregular";
}
@font-face {
  font-style: normal;
  font-weight: normal;
  src: url("https://litmus.com/fonts/Emails/proximanova-bold-webfont.woff");
  font-family: "proxima_nova_rgbold";
}

@font-face {
  font-style: normal;
  font-weight: normal;
  src: url("https://litmus.com/fonts/Emails/adelle_reg-webfont.woff");
  font-family: "adelle_rgregular";
}

body {
  width: 100vw;
  height: 100vw;
  overflow: hidden;
  color: var(--spaceGrey);
  font-family: "proxima_nova_rgbold", Helvetica, sans-serif;
}

h1 {
  font-family: "proxima_nova_rgbold", Helvetica, sans-serif;
}

:root {
  --primary-color: #ff0000;
  --secondary-color: #00ff00;
  --font-size: 16px;
  --background-color: rgb(246, 245, 243);
  --background-color-dark: rgb(255, 255, 255);
  --dark-font-color: #070707;
  --text-shadow: 0 0 0.5vw rgba(0, 0, 0, 0.4);
  --gap: 0.6vw;
  --spaceGrey: #1d1d1f;
  --dropShadow: 0 0vw 0.5vw rgba(0, 0, 0, 0.1);
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #282c34;
  min-height: 100vh;
  color: white;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.background {
  position: fixed;
  opacity: 1;
  z-index: 1;
  background: linear-gradient(
    135deg,
    #f28d00d8 0%,
    #ffaa00 90%,
    #21468b 90%,
    #21468b 93.33%,
    #ffffff 93.33%,
    #ffffff 96.66%,
    #ae1c28 96.66%,
    #ae1c28 100%
  );
  width: 100vw;
  height: 100vh;
}
