.statsMetric {
  display: flex;
  flex: 1;
  flex-direction: column;
  box-shadow: var(--dropShadow);
  border-radius: var(--gap);
  background-color: white;
  width: 100%;
  overflow: hidden;
}

.statsMetricHeading {
  background-color: var(--spaceGrey);
  padding: 0.6vw;
  color: white;
  font-weight: bold;
  font-size: 1.2vw;
}

.statsMetricValueAndUnit {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.statsMetricValue {
  font-weight: bold;
  font-size: 3vw;
  line-height: 80%;
}

.statsMetricUnit {
  font-size: 1.2vw;
}
