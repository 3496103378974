.timings {
  grid-gap: 0.2vw;
  display: grid;
  grid-template-rows: repeat(10, minmax(0, 1fr));
  justify-items: left;
  width: 100%;
  overflow: visible;
  text-align: center;
}

.timing {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5vw;
  box-sizing: border-box;
  box-shadow: var(--dropShadow);
  border-radius: 0.2vw;
  padding: 0 0.2vw 0 0.2vw;
  width: 100%;
  max-height: 1.8vw;
  overflow-y: hidden;
  font-size: 0.65vw;

  .rank {
    width: 1.3vw;
    font-size: 1.3vw;
    text-align: center;
  }

  .name {
    -webkit-box-orient: vertical;
    -ms-line-clamp: 2;
    line-clamp: 2;
    display: -webkit-box;
    flex: 1;
    box-sizing: border-box;
    margin: 0;
    -webkit-line-clamp: 2;
    overflow: hidden;
    line-height: 85%;
    text-align: left;
    word-break: break-word;
  }

  .time {
    min-width: 3vw;
    line-height: 0;
  }

  .athletesResultsBoxNumber {
    padding-left: 0.3vw;
    max-height: 1.9vw;
    font-size: 1.6vw;
    letter-spacing: -0.1vw;
    text-align: left;
  }
}
